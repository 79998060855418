import { createFilterBuilder } from '@jebel/utils';
import { useSchoolConfiguration } from './useSchoolConfiguration';
import { useSchoolConfigurations } from './useSchoolConfigurations';
import { SchoolConfigurationFilter } from 'shared/graphql';
import { useMemo } from 'react';

type Options = Parameters<typeof useSchoolConfigurations>[0] & {
  /**
   * Exclude the current school from the results.
   * @default false
   */
  skipCurrentSchool?: boolean;
};

/**
 * Custom hook to fetch the current user's schools.
 *
 * This hook uses the `useSchoolConfigurations` query to retrieve the school configurations associated with the current user.
 * @ticket https://github.com/jebelapp/jebel/issues/1562
 */
export function useCurrentUserSchools(options?: Options) {
  const { data: currentSchool } = useSchoolConfiguration();

  const filter: SchoolConfigurationFilter = useMemo(() => {
    const filter = createFilterBuilder<SchoolConfigurationFilter>(options?.variables?.filter);

    // Filter by only the current user's schools.
    filter.and({ users: { some: { is_self: true } } });

    if (options?.skipCurrentSchool && currentSchool) {
      // Exclude the current school from the results.
      filter.and({ id: { not_equals: currentSchool.id } });
    }

    return filter.build();
  }, [options, currentSchool]);

  const { data, loading } = useSchoolConfigurations({
    ...options,
    variables: { ...options?.variables, filter },
  });

  return {
    data,
    loading,
  };
}
